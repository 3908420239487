import React from "react";
import "./AmongUs.scss";

const AmongUs = () => {
    return (
        <div className="boi">
            <div className="rightleg"></div>
            <div className="leftleg"></div>
            <div className="backpack"></div>
            <div className="belly"></div>
            <div className="eye"></div>
            {/* <div className="leftleg"></div> */}

        </div>

    );
};

export default AmongUs;